import $ from "jquery";
import './favorites';

$('[name=vendor]').change(function (){
    $('[name=car]').val(0);
    getAvailableCarOptions('car');
});

$('[name=car]').change(function (){
    $('[name=year]').val(0)
    getAvailableCarOptions('year');
});

$('[name=year]').change(function (){
    $('[name=modification]').val(0)
    getAvailableCarOptions('modification');
});



function getAvailableCarOptions(selected) {
    let carFilters = {
        selected: selected,
        params: {
            vendor: $('[name=vendor]').val(),
            car: $('[name=car]').val(),
            year: $('[name=year]').val(),
            modification: $('[name=modification]').val(),
        }
    }

    $.ajax({
        url: '/auto/api/get_properties',
        type: 'POST',
        dataType: 'json',
        data: carFilters,
        beforeSend: function(){
            $('.filter_list').fadeTo('slow',.6);
            $('.filter_list').css('cursor', 'wait');
            $('.filter_list').append('<div class="faded" style="position: absolute;top:0;left:0;width: 100%;height:100%;z-index:2;opacity:0.4;filter: alpha(opacity = 50)"></div>');

            if(selected == 'car') {
                $('[name=car]').prop('disabled', true);
                $('[name=year]').prop('disabled', true);
                $('[name=modification]').prop('disabled', true);
                $('[name=car] option').detach('option');
                $('[name=year] option').detach('option');
                $('[name=modification] option').detach('option');
            }

            if(selected == 'year') {
                $('[name=year]').prop('disabled', true);
                $('[name=modification]').prop('disabled', true);
                $('[name=year] option').detach('option');
                $('[name=modification] option').detach('option');
            }

            if(selected == 'modification') {
                $('[name=modification]').prop('disabled', true);
                $('[name=modification] option').detach();
            }
        },
        success: function (data) {
            if(selected == 'car') {
                $('[name=car]').prop('disabled', false);
                $('[name=car]').append($('<option value="0">--</option>'));
                data.forEach(function (elem) {
                    let option = $('<option value="' + elem['car'] + '">' + elem['car'] + '</option>')
                    $('[name=car]').append(option);
                });
            }

            if(selected == 'year') {
                $('[name=year]').prop('disabled', false);
                $('[name=year]').append($('<option value="0">--</option>'));
                data.forEach(function (elem) {
                    let option = $('<option value="' + elem['year'] + '">' + elem['year'] + '</option>')
                    $('[name=year]').append(option);
                });
            }

            if(selected == 'modification') {
                $('[name=modification]').prop('disabled', false);
                $('[name=modification]').append($('<option value="0">--</option>'));
                data.forEach(function (elem) {
                    let option = $('<option value="' + elem['modification'] + '">' + elem['modification'] + '</option>')
                    $('[name=modification]').append(option);
                });
            }
        },
        complete: function () {
            $('.filter_list').css('cursor', 'default');
            $('.filter_list').fadeTo('slow',1);
            $('.filter_list').children('.faded').remove();

        }
    })

}
var searchTimeout = null
$('#search').keyup(function() {
    if (searchTimeout) {
        clearTimeout(searchTimeout);
    }
    if (document.getElementById('search').value !== '') {
        $('.window_search').removeClass('d-none');
        searchTimeout = setTimeout(function() {
            sendSearchRequest()
        }, 500);
    } else {
        $('.window_search').css('opacity', '0');
        searchTimeout = setTimeout(function() {
            $('.window_search').addClass('d-none');
            $('.window_search').css("overflow-y", "none");
            $('.window_search').css("height", "auto");
        }, 100);
    }
});

$('.window_search').on('click', '.search_footer', function (e) {
    $('.window_search .expanded').show()
    $('.window_search').css("overflow-y", "scroll");
    $('.window_search').css("height", "70vw");
})

let searchBlock = $('.post.search-item-default').first();
let showMoreSearch = $('.search_footer').first();

function  sendSearchRequest() {
    $.ajax({
        url: '/product/api/search',
        type: 'GET',
        dataType: 'json',
        data: {search: document.getElementById('search').value,},
        beforeSend: function() {
            $('.window_search').css("overflow-y", "none");
            $('.window_search').css("height", "auto");
            $('.window_search').fadeTo('slow', .6);
            $('.window_search').css('cursor', 'wait');
            $('.window_search').append('<div class="faded" style="position: absolute;top:0;left:0;width: 100%;height:100%;z-index:2;opacity:0.4;filter: alpha(opacity = 50)"></div>');
        },
        success: function (data) {

            $('.window_search').empty();

            let counter = 0;
            data.tiers.forEach(function (tier) {
                let searchBlockItem = searchBlock.clone().show();
                searchBlockItem.find('img').attr('src', tier.mainPicture);
                searchBlockItem.find('a').attr('href', searchBlockItem.find('a').attr('href') + '/' + tier.id);
                searchBlockItem.find('.product_name').html(tier.name);
                searchBlockItem.find('.product_price').html(tier.shownSalePrice + ' грн');
                searchBlockItem.find('.type').attr('data-text', tier.season);
                if (counter > 3) {
                    searchBlockItem.addClass('expanded');
                    searchBlockItem.hide();

                }
                $('.window_search').append(searchBlockItem);
                counter += 1;
            });

            if (counter > 3) {
                showMoreSearch.show();
                showMoreSearch.find('a').attr('data-value', data.tiers.length - 3);
                $('.window_search').append(showMoreSearch);
            }

        },
        complete: function () {
            $('.window_search').css('opacity', '1');
            $('.window_search').css('cursor', 'default');
            $('.window_search').fadeTo('slow',1);
            $('.window_search').children('.faded').remove();

        }
    })
}