// Libs
import $ from 'jquery';
import 'popper.js';
import 'jquery.cookie'
import 'jquery-mask-plugin'
import '../../node_modules/bootstrap';
import './home.js';
import './catalog.js';
import * as cart from './cart';

window.$ = $;

$(document).ready(function () {
    cart.initialize();
});

// Radio buttons
$(document).ready(function () {
    $("label").on("click", function () {
        if ($(this).find('input[type="radio"]').is(':checked')) {
            $(this).parent().parent().find('label').removeClass('active');
            $(this).addClass('active');
        }
    });
});

// Menu Right
$(document).ready(function () {
    $("ul[data-option=\"type_car\"] label > input[type=\"radio\"]").on("change", function () {
        if ($(this).is(':checked')) {
            $('.car_type').css('opacity', '0');
            let value = $(this).attr('value');
            $('.car_type').attr('src', value);
            $('.car_type').css('opacity', '1');
        }

    });
});

// Filter
$(document).ready(function () {
    $("ul[data-option=\"type_car\"] label > input[type=\"radio\"]").on("change", function () {
        let type = 1;
        if ($('ul[data-option=\"type_car\"] label > input[data-filter=\"1\"]').is(':checked')) {
            type = 1

        } else if ($('ul[data-option=\"type_car\"] label > input[data-filter=\"2\"]').is(':checked')) {
            type = 2
        } else if ($('ul[data-option=\"type_car\"] label > input[data-filter=\"3\"]').is(':checked')) {
            type = 3
        }

        filters.type = type;
        resetFilters();
    });
});

// Burger
$(document).ready(function () {
    $(".menu_burger").on("click", function () {
        if ($(this).find('input[type="checkbox"]').is(':checked')) {
            $('.mobile_menu_list').addClass('active');
        } else {
            $('.mobile_menu_list').removeClass('active');
        }
    });
});

// Write a feedback
$(document).ready(function () {
    $('.add_reviews').click(function () {
        $('.write_a_feedback').css('right', '0');
        $('.write_a_feedback').css('opacity', '1');
    });

    $('.close_reviews').click(function () {
        $('.write_a_feedback').css('right', '');
        $('.write_a_feedback').css('opacity', '');
    });

    $('#submit_review').click(function () {
        $('.write_a_feedback form button[type="submit"]').click();
    });
});

// Radio Rating
$(document).ready(function () {
    $(".radio_rating li label").on("click", function () {
        if ($(this).find('input[type="radio"]').is(':checked')) {
            $(this).parent().parent().find('li').removeClass('active');
            $(this).parent().addClass('active');
            $(this).parent().parent().attr('data-value', '- ' + $(this).parent().parent().find('li.active input').attr('value') + '/5')
        }
    });
});

// Plugins
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

import 'jquery-range/jquery.range.css';
import 'jquery-range/jquery.range-min';

$(document).ready(function () {
    $('.range-slider').jRange({
        from: window.minSalePrice,
        to: window.maxSalePrice,
        step: 1,
        scale: [0, 25, 50, 75, 100],
        format: '%s',
        width: 100 + '%',
        showLabels: true,
        isRange: false,
        onstatechange: function () {
            let values = $('.range-slider').val().split(',');
            console.log('values range', values);

            if (isNaN(values[0])) {
                values[0] = 0;
            }

            if (isNaN(values[1])) {
                values[1] = 0;
            }

            $('#minPrice').val(values[0]);
            $('#maxPrice').val(values[1]);
        },
        ondragend: function () {
            $('.max_min_price').trigger('input');
        }
    });

    // Hack
    $('#sidebar_filter').removeClass('show');
});
// Range
$(document).ready(function () {
    // $(".range-container").on("change", ".range-slider", function() {
    //     let values = $(this).val().split(',');
    //     console.log('values range',values);
    //     $('#minPrice').val(values[0]);
    //     $('#maxPrice').val(values[1]);
    //     console.log('range ',$('#maxPrice').val(), $('#minPrice').val())
    // });

    $('#minPrice').on("input", function (e) {
        let min = $(this).val();
        let max = $('#maxPrice').val();

        if (isNaN(min)) {
            min = 0;
        }
        if (isNaN(max)) {
            max = 0;
        }

        $('.range-slider').jRange('setValue', min + ',' + max)
    });

    $('#maxPrice').on("input", function (e) {
        let min = $('#minPrice').val();
        let max = $(this).val();

        if (isNaN(min)) {
            min = 0;
        }
        if (isNaN(max)) {
            max = 0;
        }
        $('.range-slider').jRange('setValue', min + ',' + max)
    })


});

$('button.reset_filter').on('mouseup', function (e) {
    console.log('reset filters');
    resetFilters();
});

function resetFilters() {
    $('#brand_filter').selectpicker('deselectAll');

    $('#brand_filter').selectpicker('refresh');
    $(".filter_content label").removeClass('active');
    $('.filter_content select').prop('selectedIndex', 0);
    let type = filters.type;

    let currentOrder = $('#order-by').val();

    filters = {
        type: type,
        height: 0,
        width: 0,
        diameter: 0,
        priceFrom: 0,
        priceTo: 0,
        season: [],
        spikes: [],
        isForced: [],
        manufacturer: [],
        page: 1,
        offset: 14,
        runFlat: 0
    }

    if (currentOrder == 0) {
        filters.order_by = 0;
        filters.asc = true;
    }
    if (currentOrder == 1) {
        filters.order_by = 'shownSalePrice';
        filters.asc = false;
    }
    if (currentOrder == 2) {
        filters.order_by = 'shownSalePrice';
        filters.asc = true;
    }
    if (currentOrder == 3) {
        filters.order_by = 'recommended';
        filters.asc = false;
    }
    sendFilters('all');
    return true;
}

// $('.change_type').click(function (e) {
//     e.preventDefault();
//     let type = $(this).data('value')
//     filters.type = type;
//     resetFilters()
// })

// Checkbox

$("label.form-check-label").click(function (e) {
    e.preventDefault();
    if (!$(this).hasClass('active')) {
        if ($(this).find('input').val() == 'зимняя') {
            $(".filter_content .spikes").show()
        }
        $(this).addClass('active');

        if ($(this).attr('for') == 'isRecommended') {
            $('#isRecommended').prop('checked', true);
        }

    } else {
        if ($(this).find('input').val() == 'зимняя') {
            $(".filter_content .spikes").hide()
        }
        $(this).removeClass('active');

        if ($(this).attr('for') == 'isRecommended') {
            $('#isRecommended').prop('checked', false);
        }
    }

    if ($(this).hasClass('season')) {

        let val = $(this).children('input').val();
        let inArray = filters.season.indexOf(val) > -1;
        if ($(this).hasClass('active')) {
            !inArray && filters.season.push(val)
        } else {
            inArray && filters.season.splice(filters.season.indexOf(val), 1)
        }
        sendFilters('season');
    };

    if ($(this).hasClass('spike')) {
        let val = $(this).children('input').val();
        let inArray = filters.spikes.indexOf(val) > -1;
        if ($(this).hasClass('active')) {
            !inArray && filters.spikes.push(val)
        } else {
            inArray && filters.spikes.splice(filters.spikes.indexOf(val), 1)
        }
        sendFilters('spikes');
    }

    if ($(this).hasClass('forced')) {
        let val = $(this).children('input').val();
        let inArray = filters.isForced.indexOf(val) > -1;
        if ($(this).hasClass('active')) {
            !inArray && filters.isForced.push(val)
        } else {
            inArray && filters.isForced.splice(filters.isForced.indexOf(val), 1)
        }
        sendFilters('forced');
    }

    if ($(this).hasClass('runFlat')) {
        if ($(this).hasClass('active')) {
            filters.runFlat = 1;
        } else {
            filters.runFlat = 0;
        }
        sendFilters('runFlat');
    }
});

import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import 'bootstrap-select/dist/js/bootstrap-select.min.js';
import 'bootstrap-select/dist/js/i18n/defaults-ru_RU';

// To style only selects with the my-select class
$('#brand_filter').selectpicker();
$(document).on('changed.bs.select', '#brand_filter', function (e, clickedIndex, isSelected) {
    console.log(e, clickedIndex, isSelected)
    if (clickedIndex == null) return;
    let targetValue = $('#brand_filter').children().eq(clickedIndex).val();
    console.log([$('#brand_filter'), targetValue, $('#brand_filter').children().eq(clickedIndex), document.querySelector('.manufacturer_checkbox[value="' + targetValue + '"]')]);
    let relatedCheckbox = document.querySelector('.manufacturer_checkbox[value="' + (targetValue) + '"]');
    console.log(relatedCheckbox);
    console.log('related checkboxr');
    //relatedCheckbox.parent().children('label').trigger('click');
    if (isSelected) {
        relatedCheckbox.parentElement.getElementsByTagName('label')[0].classList.add('active')

    } else {
        relatedCheckbox.parentElement.getElementsByTagName('label')[0].classList.remove('active')
    }

    let manuf = [];
    console.log(manuf);
    $('label.manufacturer.active').each(function (index, element) {
        manuf.push($(this).parent().children('input').val())
    });

    filters.manufacturer = manuf;
    sendFilters('manufacturer');


});
$(".sidebar_filter").on("click", "label.manufacturer", function (e) {

    let manuf = [];
    console.log(manuf);
    $('label.manufacturer.active').each(function (index, element) {
        manuf.push($(this).parent().children('input').val())
    })
    $('#brand_filter').selectpicker('val', manuf)

    filters.manufacturer = manuf;

    sendFilters('manufacturer');
});

// Sass
import '../sass/style.sass';


// Menu
$(window).scroll(function () {
    if ($(this).scrollTop() > 1) {
        $('header').addClass("slim");
    } else {
        $('header').removeClass("slim");
    }
});

// Template Home Event Carousel
$(document).ready(function () {
    $(".owl-carousel_brand_links").owlCarousel({
        items: 1,
        responsiveClass: true,
        responsive: {
            0: {
                items: 3,
                nav: true
            },
            480: {
                items: 3,
                nav: true
            },
            720: {
                items: 4,
                nav: true
            },
            1000: {
                items: 7,
                nav: false,
                loop: false
            }
        },
        margin: 15,
        nav: false,
        dots: false,
        loop: false,
        autoplay: false,
        video: true,
    });
});

// Template Home Event Carousel
$(document).ready(function () {
    $(".owl-carousel_action_products").owlCarousel({
        items: 1,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: true
            },
            412: {
                items: 2,
                nav: true
            },
            480: {
                items: 2,
                nav: true
            },
            1000: {
                items: 3,
                nav: true,
                loop: false
            },
            1366: {
                items: 4,
                nav: true,
                loop: false
            }
        },
        margin: 0,
        nav: false,
        dots: false,
        loop: false,
        autoplay: false,
        video: true,
    });
});

// Template Home Event Carousel
$(document).ready(function () {
    $(".owl-carousel_comparison").owlCarousel({
        items: 1,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
            },
            480: {
                items: 2,
            },
            720: {
                items: 1,
            },
            1000: {
                items: 3,
            },
            1200: {
                items: 4,
            }
        },
        nav: true,
        margin: 15,
        loop: false,
        autoplay: false,
    });
});

$(document).ready(function () {
    $('.owl-carousel_comparison').on('change.owl.carousel', function (event) {
        // let slide = $('.owl-carousel_home').find('.owl-item.active').find('.carousel_item').attr('data-slide');
        setTimeout(function () {
            let slide = $('.owl-carousel_comparison').find('.owl-item.active').find('.item').attr('data-position');
            $('.position span').html(slide);
        }, 400);
    });
});

// Rating
$('.rating .scale').each(function (i, el) {
    $(this).css('width', $(this).attr('data-progress') + '%');
});

// Tabs
$(document).ready(function () {
    $(".nav-tabs_mobile ul label > input").on("change", function () {
        if ($('.nav-tabs_mobile ul label > input[value=\"1\"]').is(':checked')) {
            $('#tab_link_1').click();
        } else if ($('.nav-tabs_mobile ul label > input[value=\"2\"]').is(':checked')) {
            $('#tab_link_2').click();
        } else if ($('.nav-tabs_mobile ul label > input[value=\"3\"]').is(':checked')) {
            $('#tab_link_3').click();
        }
    });
});

import '../js/progressbar';

// Progressbar
$(document).ready(function () {
    $('.dws-progress-bar').each(function (i, el) {
        $(el).circularProgress({
            color: "#f8c311",
            line_width: 8,
            height: "115px",
            width: "115px",
            percent: 0,
            counter_clockwise: false,
            starting_position: 0
        }).circularProgress('animate', $(el).attr('data-value'), 2000);
    });
});

// Image Changer
$(document).ready(function () {
    $(".product_image_changer ul li label input").on("change", function () {
        if ($(this).is(':checked')) {
            setTimeout(function () {
                $('.image_change').css('opacity', '0');
            }, 100);
            setTimeout(function () {
                $('.image_change img').attr('src', $('.product_image_changer ul li label.active input').next('img').attr('src'))
            }, 300);
            setTimeout(function () {
                $('.image_change').css('opacity', '1');
            }, 500);
        }
    });
});

// Reviews Link
$("a[href^='#'].reviews_link").on('click', function (e) {

    // prevent default anchor click behavior
    e.preventDefault();

    // store hash
    let hash = this.hash;

    // animate
    $('html, body').animate({
        scrollTop: $(hash).offset().top
    }, 500, function () {

        // when done, add hash to url
        // (default click behaviour)
        window.location.hash = hash;
    });
});

var filters = {
    order_by: 'recommended',
    type: window.tierType,
    height: $('#height').val() || 0,
    width: $('#width').val() || 0,
    diameter: $('#diameter').val() || 0,
    priceFrom: window.minSalePrice,
    priceTo: window.maxSalePrice,
    season: [],
    spikes: [],
    isForced: [],
    manufacturer: [],
    page: 1,
    offset: 14,
    asc: false,
    targetElem: '',
    runFlat: 0
};

let selectedSeasoneOnHomePage = false;

var requests = {
    tiers: 1,
    filters: 1,
    count: 1
};

var item = $('div.tier_item').first().clone();
item.find('.add_cart').removeClass('active')
var showMoreButton = $('div.show-more').first().clone().show();

function getAvailableFilters(element) {
    $.ajax({
        url: '/product/api/available_filters',
        type: 'POST',
        dataType: 'json',
        data: filters,
        beforeSend: function () {
            requests.filters = 0;
            $('.filter_list').css('cursor', 'wait');
            $('.filter_list').append('<div class="faded" style="position: absolute;top:0;left:0;width: 100%;height:100%;z-index:2;opacity:0.4;filter: alpha(opacity = 50)"></div>');
        },
        success: function (data) {
            requests.filters = 1;
            updateAvailableFilters(data['availableFilters'], element)
        },
        complete: function () {
            if (requests.filters == 1 && requests.count === 1 && requests.tiers === 1) {
                $('.filter_list').css('cursor', 'default');
                $('.filter_list').children('.faded').remove();
            }
        }
    })
}

function getProductsCount(element) {
    console.log(filters)
    $.ajax({
        url: '/product/api/product_count',
        type: 'POST',
        dataType: 'json',
        data: filters,
        beforeSend: function () {
            requests.count = 0;
        },
        success: function (data) {
            requests.count = 1;
            updatePagination(data['tiersCount'], filters.offset)
        },
        complete: function () {
            if (requests.filters == 1 && requests.count === 1 && requests.tiers === 1) {
                $('.filter_list').css('cursor', 'default');
                $('.filter_list').fadeTo('slow', 1);
                $('.filter_list').children('.faded').remove();
            }
        }
    })
}


function sendFilters(element, clearList = true, getJustFilters = false) {
    function countText(count) {
        let ends = window.shinshtab.texts.ends;
        let available = window.shinshtab.texts.available;

        if (count == 4) {
            return ends;
        }

        return '<span class="tier_availability_pretext">' + available + '</span> ' + count + 'шт.';
    }

    filters.targetElem = element;

    let needToResetPage = element !== 'page';
    if (needToResetPage) {
        filters.page = 1;
    }
    getAvailableFilters(element);
    if (getJustFilters) {
        return;
    }
    getProductsCount(element);

    $.ajax({
        url: '/product/api/filter',
        type: 'POST',
        dataType: 'json',
        data: filters,
        beforeSend: function () {
            requests.tiers = 0;
            $('.filter_list').css('cursor', 'wait');
            $('.filter_list').append('<div class="faded" style="position: absolute;top:0;left:0;width: 100%;height:100%;z-index:2;opacity:0.4;filter: alpha(opacity = 50)"></div>');
        },
        success: function (data) {
            let list = $('div.tiers-list');
            list.find('div.show-more').remove();
            let tiers = data['tiers'];
            let ratings = data['ratings'];

            clearList && list.empty(); // ??

            tiers.forEach(function (tier) {
                let tierItem = item.clone();

                tierItem.find('a.product-link').attr('href', window.app.links.product.replace('0', tier.id));
                tierItem.find('img.img-fluid').attr('src', tier.mainPicture);
                tierItem.find('.carousel_item').attr('data-product-id', tier.id);
                tierItem.find('div.vendor_code').attr('data-after', tier.id);
                tierItem.find('div.item_content h4').html(tier.name);
                tierItem.find('div.product_info .content_left').attr('data-text', tier.season);
                tierItem.find('div.product_price div.price').html(tier.shownSalePrice + ' грн.');
                tierItem.find('div.product_price div.availability').html(countText(tier.count));
                tierItem.data('id', tier.id);
                tierItem.data('name', tier.name);
                tierItem.find('.callback_hover').data('name', tier.name);
                tierItem.data('picture', tier.mainPicture);
                tierItem.data('price', tier.shownSalePrice);
                if (cart.isInCart(tier.id)) {
                    tierItem.find('.add_cart').addClass('active')
                }

                let seasonType = cart.getSeasonTypeId(tier.season);
                tierItem.find('.product_info .content_left').attr('data-type', seasonType);

                let seasonText = tier.season; //tier.season.charAt(0).toUpperCase() + tier.season.slice(1);
                tierItem.data('season', seasonText);

                let score = Number(ratings[tier.id].total).toString().replace(/(\.\d).*/, '$1').replace(/\.0/, '');//drop insignificant zero
                let rating = Number(ratings[tier.id].total * 2 * 10).toFixed(0);

                tierItem.find('.rating').attr('data-after', score);
                tierItem.find('.scale').attr('data-progress', rating);
                tierItem.find('.scale').css('width', rating + '%');
                tierItem.find('a.reviews').attr('href', tierItem.find('a.reviews').attr('href').replace(/product.*/, 'product/' + tier.id + '#reviews'));

                list.append(tierItem);
            });
            if (tiers.length === 14) {
                list.append(showMoreButton);
            }

            requests.tiers = 1;
        },
        complete: function () {
            if (requests.filters == 1 && requests.count === 1 && requests.tiers === 1) {
                $('.filter_list').css('cursor', 'default');
                $('.filter_list').children('.faded').remove();
            }
            history.pushState({filters: filters}, '', window.location.pathname + '?' + $.param(filters));
        }
    })
}


$('.filter_header .type').click(function (e) {
    filters.type = e.target.value;
    resetFilters();
});

$('.sizes select').change(function (e) {
    filters[e.target.name] = e.target.value;
    if (e.target.classList.contains('home-page')) {
        if (e.target.value == 0) {
            sendFilters('all', true, true);
        } else {
            sendFilters(e.target.name, true, true);
        }

    } else {
        if (e.target.value == 0) {
            sendFilters(e.target.name, true);
        } else {
            sendFilters(e.target.name, true);
        }
    }

});

$('#sidebar_filter_select_count').change(function (e) {
    filters.count = $(this).val();
    sendFilters('count');
});


var priceTimeout = null
$('.max_min_price').on('input', function () {
    if (priceTimeout) {
        clearTimeout(priceTimeout);
    }
    priceTimeout = setTimeout(function () {
        let changed = filters.priceTo != $('#maxPrice').val() || filters.priceFrom != $('#minPrice').val()
        if (changed) {
            filters.priceTo = $('#maxPrice').val();
            filters.priceFrom = $('#minPrice').val();
            sendFilters('max_min_price');
        }
    }, 2000);


});

// $('.range_container').on('mouseup mouseout', function (e) {
//     if (e.type === "mouseout") {
//         if (e.target.style.left == '0px' || e.target.style.left === '291px') {
//             $('.max_min_price').trigger('input');
//         }
//     }
//     if (e.type === "mouseup") {
//         $('.max_min_price').trigger('input');
//     }
// })

$('.pagination').on('click', '.page-link', function (e) {
    e.preventDefault();
    if ($(this).parent('.page-item').hasClass('page-prev') || $(this).parent('.page-item').hasClass('page-next')) {
        return;
    }
    let needToBeSend = filters.page != $(this).data('page');
    filters.page = $(this).data('page');
    needToBeSend && sendFilters('page');
});

$('.pagination').on('click', '.page-prev', function (e) {
    e.preventDefault();
    let needToBeSend = filters.page > 0;
    filters.page = needToBeSend ? filters.page - 1 : 0;
    needToBeSend && sendFilters('page');
});

$('.pagination').on('click', '.page-next', function (e) {
    e.preventDefault();
    let needToBeSend = filters.page < $(this).data('max-page');
    filters.page = needToBeSend ? filters.page + 1 : filters.page;
    needToBeSend && sendFilters('page');
});

$('div.tiers-list').on('click', 'div.show-more', function (e) {
    filters.page += 1;
    sendFilters('page', false);
});


function updatePagination(productsCount, offset = 14, resetPage = true) {
    let pagination = $('ul.pagination')
    let prev = $('li.page-item.page-prev').first();
    let next = $('li.page-item.page-next').first();
    let item = $('li.page-item.page-number').first().clone().removeClass('active');
    let pagesCount = (Math.floor(productsCount / offset) + 1);

    let currentPage = filters.page;
    pagination.empty();

    pagination.append(prev);

    if (currentPage >= 3) {
        let item1 = item.clone();
        item1.children('a.page-link').data('page', 1);
        item1.children('a.page-link').attr('href', '#');
        item1.children('a.page-link').html(1);
        pagination.append(item1);
    }

    if (currentPage >= 4) {
        let item2 = item.clone();
        item2.children('a.page-link').attr('href', '#');
        item2.children('a.page-link').html('...');
        pagination.append(item2);
    }

    if (currentPage > 1) {
        let item3 = item.clone();
        item3.children('a.page-link').data('page', currentPage - 1);
        item3.children('a.page-link').attr('href', '#');
        item3.children('a.page-link').html(currentPage - 1);
        pagination.append(item3);
    }

    let current = item.clone();
    current.addClass('active');
    current.children('a.page-link').data('page', currentPage);
    current.children('a.page-link').attr('href', '#');
    current.children('a.page-link').html(currentPage);
    pagination.append(current);

    if (currentPage < pagesCount) {
        let item4 = item.clone();
        item4.children('a.page-link').data('page', currentPage + 1);
        item4.children('a.page-link').attr('href', '#');
        item4.children('a.page-link').html(currentPage + 1);
        pagination.append(item4);
    }

    if (currentPage <= pagesCount - 4) {
        let item5 = item.clone();
        item5.children('a.page-link').attr('href', '#');
        item5.children('a.page-link').html('...');
        pagination.append(item5);
    }

    if (currentPage <= pagesCount - 3) {
        let item6 = item.clone();
        console.log(item6);
        item6.children('a.page-link').data('page', pagesCount);
        item6.children('a.page-link').attr('href', '#');
        item6.children('a.page-link').html(pagesCount);
        pagination.append(item6);
    }

    pagination.append(next);

    if (productsCount === 0) {
        pagination.hide();
    }
}

function updateAvailableFilters(filters, targetElem) {
    if (targetElem !== 'manufacturer') {
        updateManufacturers(filters['manufacturer']);
    }

    if (targetElem !== 'width') {
        updateWidth(filters['width']);
    }

    if (targetElem !== 'height') {
        updateHeight(filters['height']);
    }

    if (targetElem !== 'diameter') {
        updateDiameter(filters['diameter']);
    }

    if (targetElem !== 'count') {
        updateCount(filters['count']);
    }

    if (targetElem !== 'season_select' || filters['season'].length === 3) {
        //updateSeasons(filters['season']);
    }

    if (targetElem !== 'spikes') {
        //updateSpikes(filters['spikes']);
    }

    if (targetElem !== 'forced') {
        updateForced(filters['isForced']);
    }
    if (targetElem !== 'max_min_price' && targetElem !== 'page') {
        updatePrice(filters['maxSalePrice'], filters['minSalePrice']);
    }

    if (targetElem !== 'runFlat') {
        updateRunFlat(filters['runFlat']);
    }
}

function updatePrice(maxPrice = '20000', minPrice = '0') {

    let newRange = $('.range-slider').clone();
    console.log($('.range_container').find('.row').eq(2))
    $('.range_container').find('.row').eq(1).remove();
    $('.range_container').append($('<div class=row>'));
    $('.range_container').find('.row').eq(1).append($('<div class=col>'));
    $('.range_container').find('.row').eq(1).find('.col').append(newRange);

    $('.range-slider').jRange({
        from: Math.floor(parseFloat(minPrice)),
        to: Math.ceil(parseFloat(maxPrice)),
        step: 1,
        scale: [0, 25, 50, 75, 100],
        format: '%s',
        width: 100 + '%',
        showLabels: true,
        isRange: false,
        onstatechange: function () {
            let values = $('.range-slider').val().split(',');
            console.log('values range', values);
            $('#minPrice').val(values[0]);
            $('#maxPrice').val(values[1]);
        },
        ondragend: function () {
            $('.max_min_price').trigger('input');
        }
    });

    if (isNaN(minPrice)) {
        minPrice = 0;
    }

    if (isNaN(maxPrice)) {
        maxPrice = 0;
    }

    $('.range-slider').jRange('setValue', Math.floor(parseFloat(minPrice)) + ',' + Math.ceil(parseFloat(maxPrice)));
    $('#minPrice').val(Math.floor(parseFloat(minPrice)));
    $('#maxPrice').val(Math.ceil(parseFloat(maxPrice)));
}

var defbrand = $('.brands_list').find('div.form-group').first();

var defcollapsed = $('#collapse_brands').first();
var defcollapseButton = $('.brands_list').find('button.collapse_button').first();


function updateManufacturers(manufacturers) {
    let defaultOption = $('#brand_filter').find('option').first().clone(true, true);
    $('#brand_filter').empty();
    $('#brand_filter').append(defaultOption);
    manufacturers.forEach(function (elem) {
        let option = defaultOption.clone(true, true);
        option.val(elem.id);
        option.html(elem.name);
        $('#brand_filter').append(option);
    });

    let defaultelectOption = $('[name=manufacturer]').find('option').first().clone(true, true).attr('selected', 'selected');
    $('[name=manufacturer]').empty();
    $('[name=manufacturer]').append(defaultelectOption);
    defaultelectOption.removeAttr('selected', false);
    manufacturers.forEach(function (elem) {
        let option = defaultelectOption.clone(true, true);
        option.val(elem.id);
        option.html(elem.name);
        $('[name=manufacturer]').append(option);
    });

    let brand = defbrand.clone(true, true)

    let collapsed = defcollapsed.clone(true, true)
    collapsed.find('.card').empty();

    let collapseButton = defcollapseButton.clone(true, true).show();
    collapseButton.attr('data-content', window.translations.show_more_x + ' ' + manufacturers.length - 7);
    collapseButton.attr('data-content-up', window.translations.hide + ' ' + manufacturers.length - 7);
    $('.brands_list').children().detach();
    for (let i = 0; i < manufacturers.length; i++) {
        if (i == 8) {
            break;
        }
        let brandItem = brand.clone(true);
        brandItem.find('label').html(manufacturers[i].name);
        brandItem.find('label').attr('for', 'manufacturer_' + manufacturers[i].id);
        brandItem.find('input').attr('id', 'manufacturer_' + manufacturers[i].id);
        brandItem.find('input').val(manufacturers[i].id);
        $('.brands_list').append(brandItem);
    }

    if (manufacturers.length > 8) {

        for (let i = 8; i < manufacturers.length; i++) {

            let brandItem = brand.clone(true, true);
            brandItem.find('label').html(manufacturers[i].name);
            brandItem.find('label').attr('for', 'manufacturer_' + manufacturers[i].id);
            brandItem.find('input').attr('id', 'manufacturer_' + manufacturers[i].id);
            brandItem.find('input').val(manufacturers[i].id);
            collapsed.find('.card').append(brandItem);
        }

        $('.brands_list').append(collapsed);
        $('.brands_list').append(collapseButton);
    }

    $('#brand_filter').selectpicker('destroy');
    $('#brand_filter').selectpicker();

}

function updateWidth(widthArr) {
    widthArr.sort(function (a, b) {
        return a - b;
    });

    $('[name=width]').empty();
    $('[name=width]').append('<option value="0" selected="">' + window.translations.all + '</option>')
    widthArr.forEach(function (element) {
        let option = $('<option value="' + element + '">' + element + '</option>');
        if (element == filters.width) option.attr('selected', 'selected')
        $('[name=width]').append(option)
    });
}

function updateHeight(heightArr) {
    heightArr.sort(function (a, b) {
        return a - b;
    });

    $('[name=height]').empty();
    $('[name=height]').append('<option value="0" selected="">' + window.translations.all + '</option>')
    heightArr.forEach(function (element) {
        let option = $('<option value="' + element + '">' + element + '</option>');
        if (element == filters.height) option.attr('selected', 'selected');
        if (element != null) {
            $('[name=height]').append(option);
        }
    });
}

function updateDiameter(diameterArr) {
    diameterArr.sort(function (a, b) {
        return a - b;
    });

    $('[name=diameter]').empty();
    $('[name=diameter]').append('<option value="0" selected="">' + window.translations.all + '</option>')
    diameterArr.forEach(function (element) {
        let option = $('<option value="' + element + '">' + element + '</option>');
        if (element == filters.diameter) option.attr('selected', 'selected')
        $('[name=diameter]').append(option)
    });
}

function updateCount(countArr) {
    console.log(countArr);
    countArr.sort(function (a, b) {
        return a - b;
    })
    $('#sidebar_filter_select_count').empty();
    $('#sidebar_filter_select_count').append('<option value="0" selected="">' + window.translations.all + '</option>')
    countArr.forEach(function (element) {
        let option = $('<option value="' + element + '">' + element + '</option>');
        if (element == filters.count) option.attr('selected', 'selected')
        $('#sidebar_filter_select_count').append(option)
    });
}

function updateSeasons(seasonsArr) {

    $('.season').each(function () {
        if (seasonsArr.includes($(this).find('input').val()) || $(this).find('input').val() == 'всесез.' && seasonsArr.includes('всесезонная')) {
            //$(this).show()
            $(this).css('pointer-events', 'auto');
            $(this).fadeTo('fast', 1);
        } else {
            $(this).css('pointer-events', 'none');
            $(this).fadeTo('fast', .6);
        }
    });

    seasonsArr.sort(function (a, b) {
        return a - b;
    });

    $('[name=season_select]').empty();
    $('[name=season_select]').append('<option value="0" selected="">' + window.translations.all + '</option>');
    seasonsArr.forEach(function (element) {
        let option = $('<option value="' + element + '">' + element + '</option>');
        if (element == filters.season || selectedSeasoneOnHomePage == element) {
            option.attr('selected', 'selected');
        }
        $('[name=season_select]').append(option);
    });
}

function updateSpikes(seasonsArr) {
    $('.spike').each(function () {
        console.log('spikes', $(this))
        //let isSpike = $(this).find('input').val() == "1"
        if (seasonsArr.includes($(this).find('input').val())) {
            $(this).css('pointer-events', 'auto');
            $(this).fadeTo('fast', 1);
            //$(this).show()
        } else {
            //$(this).hide();
            $(this).css('pointer-events', 'none');
            $(this).fadeTo('fast', .6);
        }
    })
}

function updateForced(forcedArr) {
    $('.forced').each(function () {

        if (forcedArr.includes($(this).find('input').val())) {
            $(this).css('pointer-events', 'auto');
            $(this).fadeTo('fast', 1);
            //$(this).show()
        } else {
            //$(this).hide();
            $(this).css('pointer-events', 'none');
            $(this).fadeTo('fast', .6);
        }
    })
}

function updateRunFlat(runFlat) {
    if (runFlat > 0) {
        $('.runFlat').css('pointer-events', 'auto');
        $('.runFlat').fadeTo('fast', 1);
    } else {
        $('.runFlat').css('pointer-events', 'none');
        $('.runFlat').fadeTo('fast', .6);
    }
}

$('#order-by').change(function (e) {
    let val = $(this).val();
    console.log(val, $(this));
    if (val == 0) {
        filters.order_by = 0;
        filters.asc = true;
    }
    if (val == 1) {
        filters.order_by = 'shownSalePrice';
        filters.asc = false;
    }
    if (val == 2) {
        filters.order_by = 'shownSalePrice';
        filters.asc = true;
    }
    if (val == 3) {
        filters.order_by = 'recommended';
        filters.asc = false;
    }
    sendFilters();
});

$('body').on('click', 'a', function () {
    handleRedirect();
    return true;
});

function handleRedirect() {
    let isCatalogPage = window.location.href.indexOf('catalog') !== -1;
    if (isCatalogPage) {
        filters.fromState = true;
        history.pushState({filters: filters}, '', window.location.pathname + '?' + $.param(filters));
    }
}

if (history.state && history.state.fromState) {
    filters = history.state;
    sendFilters('all');
}

let $body = $('body');
$body
    .find('#checkout_phone').each(function () {
    $(this).mask("+38 (999) 999-99-99", {autoсlear: false});
});

$body.on('keyup', '#checkout_phone', function () {
    var phone = $(this),
        phoneVal = phone.val(),
        form = $(this).parents('form');

    if (phone.val() == '' || phone.val().length < 18 || phone.val().length > 19) {
        phone.get(0).setCustomValidity(window.cart_phone_error_message);

    } else {
        phone.get(0).setCustomValidity("")

    }
});

$(document).on('click', '.callback_hover', function (event) {
    let productId = $(event.target).closest('.callback_hover').data('name');
    console.log($(event.target).closest('.callback_hover'));
    console.log(productId);
    $('#callback-modal-id').val(productId);
    $('#callback-modal').modal();
    event.preventDefault();
    return false;
});