import $ from "jquery";

function cartShow() {
    $('.cart').css('right', '0');
    $('.cart').css('opacity', '1');
}

function cartHide() {
    $('.cart').css('right', '');
    $('.cart').css('opacity', '');
}

function initialize() {
    $('.btn_cart').click(function () {
        cartShow();
    });
    $('.cart_close').click(function () {
        cartHide();
    });
    $('.cart_btn_shopping').click(function () {
        cartHide();
    });

    $('.tiers-list').on('click', '.add_cart', function (e) {
        e.preventDefault();
        if (!$(this).hasClass('active')) {
            let tier = $(this).parents('.tier_item');
            let product = {
                id: tier.data('id'),
                name: tier.data('name'),
                mainPicture: tier.data('picture'),
                season: tier.data('season'),
                price: tier.data('price'),
                totalCount: tier.data('total_count')
            };
            addToCart(product);
            $(this).addClass('active');
            cartShow();
        } else {
            let id = $(this).closest('[data-id]').data('id');
            $('.cart_body').find('[data-id=' + id + ']').detach();
            removeFromCartCookie(id);
            $(this).removeClass('active');
        }
        updateTotalPrice();

    });

    $('.cart_container').on('click', '.plus_minus .minus', function () {
        let $input = $(this).parent().find('input');
        let count = parseInt($input.val()) - 1;
        count = count < 1 ? 1 : count;
        $input.val(count);

        changeCount($input.get());
        $input.change();
        return false;
    });

    $('.cart_container').on('click', '.plus_minus .plus', function () {
        let cartElement = $(this).closest('.cart_item');
        let maxValue = cartElement.data('total_count');

        let $input = $(this).parent().find('input');
        let newValue = parseInt($input.val()) + 1;

        if (newValue > maxValue) {
            newValue = maxValue;
        }

        $input.val(newValue);

        changeCount($input.get());
        $input.change();
        return false;
    });
}

function addToCartCookie(product, count = 1) {
    let cookies = $.cookie('cart_items') ? JSON.parse($.cookie('cart_items')) : [];
    let toAdd = {id: product.id, 'count': count, totalCount: product.totalCount};

    cookies.push(toAdd);
    $.cookie('cart_items', JSON.stringify(cookies), {expires: 100, path: '/'});
}

function changeCartItemCountCookie(itemId, count = 1) {
    let cookies = $.cookie('cart_items') ? JSON.parse($.cookie('cart_items')) : [];
    cookies.find(function (elem, index, arr) {
        if (elem.id == itemId) {
            arr[index].count = count;
            return true;
        }
    });

    $.cookie('cart_items', JSON.stringify(cookies), {expires: 100, path: '/'});
}

function removeFromCartCookie(itemId) {
    let cookies = $.cookie('cart_items') ? JSON.parse($.cookie('cart_items')) : [];
    cookies.find(function (elem, index, arr) {
        if (elem.id == itemId) {
            arr.splice(index, 1);
            return true;
        }
    });
    $.cookie('cart_items', JSON.stringify(cookies), {expires: 100, path: '/'});
}

function isInCart(itemId) {
    let cookies = $.cookie('cart_items') ? JSON.parse($.cookie('cart_items')) : [];
    let finded = false;
    cookies.find(function (elem, index, arr) {
        if (elem.id == itemId) {
            finded = true;
        }
    });
    return finded;
}

function addToCart(product) {
    let cartItem = $('.cart_body .post').first().clone(true, true).show();
    let seasonType = getSeasonTypeId(product.season);

    cartItem.find('.img-fluid').attr('src', product.mainPicture);
    cartItem.find('.product_name').html(product.name);
    cartItem.find('.vendor_code').attr('data-after', product.id);
    cartItem.attr('data-id', product.id);
    cartItem.attr('class', cartItem.attr('class') + ' cart_item');
    cartItem.find('.type').attr('data-text', product.season);
    cartItem.find('.type').attr('data-type', seasonType);
    cartItem.find('.price').html(product.price + ' грн.');
    cartItem.find('.price').attr('data-price', product.price);

    cartItem.attr('data-total_count', product.totalCount);

    $('.cart_body').append(cartItem);
    addToCartCookie(product);
    updateTotalPrice();
}

function getProductCountInShop(element) {
    let product = $(element).closest('[data-id]');

    return product.data('count');
}

function changeCount(elem) {
    let count = $(elem).val();
    let id = $(elem).closest('[data-id]').data('id');
    let priceElem = $(".cart_container").find("[data-id='" + id + "'] .price");

    priceElem.each(function () {
        if ($(this).data('info')) {
            $(this).attr('data-info', '(цена за ' + count + ' шт)');
            console.log($(this).data('info'))
        }
    });

    let singlePrice = parseInt(priceElem.data('price'));
    priceElem.html((count * singlePrice) + ' грн');
    $(".cart_container").find("[data-id='" + id + "'] .plus_minus input").val(count);

    updateTotalPrice();
    changeCartItemCountCookie(id, count);
}

function updateTotalPrice() {
    let fullTotalPrice = 0;
    $('.cart_container').first().find('.price').map(function () {
        if (parseInt($(this).html()) > 0) {
            fullTotalPrice = fullTotalPrice + parseInt($(this).html())
        }
    });
    $('.cart_container .full_price span, .btn_cart span.ml-1').html(fullTotalPrice + ' грн');
    $('.cart_container .full_price').data('total', fullTotalPrice)
    updateTotalCount();
}

function updateTotalCount() {
    let cartTotalCount = -1;
    $('.cart_container').first().find('input.count').map(function () {
        cartTotalCount += parseInt($(this).val())
    });

    $('.btn_cart span.total_cart_count_1').html(cartTotalCount + ' тов.');
}

$('.cart_container').on('click', '.clear', function () {
    let id = $(this).closest('[data-id]').data('id');
    $('.cart_body').find('[data-id=' + id + ']').detach();

    if ($('.cart_container')) {
        $('.cart_container').find('[data-id=' + id + ']').detach();
    }

    $('.tier_item[data-id=' + id + ']').find('.add_cart').removeClass('active');
    removeFromCartCookie(id);
    updateTotalPrice();
});

// Also modify App\Twig\SeasonTypeId#seasonTypeId
function getSeasonTypeId(season) {
    let seasonType = 1;
    if (-1 != season.toLowerCase().indexOf('зимняя')) {
        seasonType = 2;
    }
    if (-1 != season.toLowerCase().indexOf('всесез')) {
        seasonType = 3;
    }

    return seasonType;
}

export {
    isInCart,
    getSeasonTypeId,
    initialize
}