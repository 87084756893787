import $ from "jquery";

window.favoritesRemoveFromPage = favoritesRemoveFromPage;

let currentFavorites = window.app.favorites.current;
const URL_ADD_TO_FAVORITES = window.app.favorites.urls.add;
const URL_REMOVE_TO_FAVORITES = window.app.favorites.urls.remove;

$('body').on('click', 'label.add_favorites', function (event) {
    event.preventDefault();
    let $label = $(event.currentTarget);
    let id;

    if ($label.attr('data-id')) {
        id = $label.attr('data-id');
        console.log('got id from local data: ', id);
    } else {
        id = $label.parent().parent().attr('data-product-id');
        console.log('got id from local parent paredata: ', id);
    }

    if ($label.hasClass('active')) {
        favoritesRemove(id);
        $label.removeClass('active');
    } else {
        favoritesAdd(id);
        $label.addClass('active');
    }
});

function favoritesRemove(idToRemove) {
    fetch(URL_REMOVE_TO_FAVORITES.replace('ID', idToRemove), {
        credentials: 'include'
    }).then(() => {
        currentFavorites = currentFavorites.filter(id => id != idToRemove);
        changeTotalText();
    });
}

function favoritesAdd(idToAdd) {
    fetch(URL_ADD_TO_FAVORITES.replace('ID', idToAdd), {
        credentials: 'include'
    }).then(() => {
        currentFavorites.push(idToAdd);
        changeTotalText();
    });
}

function changeTotalText() {
    let total = Object.keys(currentFavorites).length;
    $('#favorites_count_text').text(total);
}

function favoritesRemoveFromPage(idToRemove, itemIndex) {
    favoritesRemove(idToRemove);
    $('.owl-carousel_comparison').trigger('remove.owl.carousel', [itemIndex]).trigger('refresh.owl.carousel');
}

changeTotalText();